import { createContext, useContext, useState, useEffect } from "react";
import { supabase } from "../supabase/client";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [userState, setUserState] = useState({
    authSession: null,
    accessToken: null,
    userProfile: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data: sessionData, error: sessionError } =
          await supabase.auth.getSession();

        if (sessionError || !sessionData.session) {
          console.error("Error fetching session or no active session found.");
          return;
        }

        const { session } = sessionData;
        const accessToken = session.access_token;

        const { data: user, error: userError } = await supabase
          .from("users")
          .select(
            "id, first_name, last_name, full_name, email, active, created_at, last_sign_in, updated_at, auth_user_id, role_id(role), user_hotel (hotels(id, name, code, active))"
          )
          .eq("auth_user_id", session.user.id)
          .single();

        if (userError || !user) {
          console.error("Error fetching user information or no user found.");
          return;
        }

        setUserState({
          authSession: session,
          accessToken,
          userProfile: user,
        });
      } catch (error) {
        console.error("Unexpected error:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();

    // ======================================================================
    // Subscribe to session changes (onAuthStateChange)
    const { data: sub } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN" || event === "TOKEN_REFRESHED") {
        console.log("Session refreshed or signed in.");
        const accessToken = session?.access_token;

        // Fetch the user's profile if needed
        const fetchUserProfile = async () => {
          try {
            const { data: user } = await supabase
              .from("users")
              .select(
                "id, first_name, last_name, full_name, email, active, created_at, last_sign_in, updated_at, auth_user_id, role_id(role), user_hotel (hotels(id, name, code, active))"
              )
              .eq("auth_user_id", session?.user?.id)
              .single();

            setUserState({
              authSession: session,
              accessToken,
              userProfile: user,
            });
          } catch (error) {
            console.error("Unexpected error:", error.message);
          } finally {
            setLoading(false);
          }
        };

        fetchUserProfile();
      } else if (event === "SIGNED_OUT") {
        console.log("User signed out.");
        setUserState({
          authSession: null,
          accessToken: null,
          userProfile: null,
        });
      }
    });

    // Clean up the subscription when the component unmounts
    return () => {
      sub.subscription.unsubscribe();
    };
    // ======================================================================
  }, []);

  const updateUser = (user) => {
    setUserState({
      authSession: user.session,
      accessToken: user.session?.access_token,
      userProfile: user.profile,
    });
  };

  return (
    <UserContext.Provider value={{ ...userState, loading, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};
