import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./EditHotel.module.css";
import { useState, useEffect } from "react";
import { supabase } from "../supabase/client";
import Loader from "../components/ui/elements/custom/Loader";
import { IoChevronForwardSharp } from "react-icons/io5";

function EditHotel() {
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [locality, setLocality] = useState("");
  const [provider, setProvider] = useState("");
  const [apiId, setApiId] = useState("");
  const [active, setActive] = useState(false);
  const [originalData, setOriginalData] = useState();

  const [propertyTypes, setPropertyTypes] = useState([]);
  const [providers, setProviders] = useState([]);
  const [localities, setLocalities] = useState([]);

  const navigate = useNavigate();
  const params = useParams();

  const fetchHotel = async (id) => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from("hotels")
        .select(
          "name, code, property_type_id, api_provider_id, api_identifier, active"
        )
        .eq("id", id)
        .single();

      if (error) {
        toast.error(
          () => (
            <>
              <p>Failed to retrieve hotel information!</p>
              <p className={styles.info}>Reason: {error.message}</p>
            </>
          ),
          {
            toastId: "errortoast",
          }
        );
        navigate("/hotels");
        return;
      }

      setOriginalData({
        name: data.name,
        code: data.code,
        property_type: data.property_type_id,
        api_provider: data.api_provider_id,
        api_identifier: data.api_identifier,
        active: data.active,
      });

      setName(data.name);
      setCode(data.code);
      setPropertyType(data.property_type_id);
      setProvider(data.api_provider_id);
      setApiId(data.api_identifier);
      setActive(data.active);
    } catch (error) {
      toast.error(
        () => (
          <>
            <p>Failed to retrieve hotel information!</p>
            <p className={styles.info}>Reason: {error.message}</p>
          </>
        ),
        {
          toastId: "errortoast",
        }
      );
      navigate("/hotels");
    }

    setIsLoading(false);
  };

  const fetchProviders = async () => {
    try {
      const { data, error } = await supabase
        .from("api_service_providers")
        .select();

      if (error) {
        throw error;
      }

      setProviders(data);
    } catch (error) {
      toast.error("Failed to fetch API providers: " + error);
      return null;
    }
  };

  const fetchPropertyTypes = async () => {
    try {
      const { data, error } = await supabase.from("property_types").select();

      if (error) {
        throw error;
      }

      setPropertyTypes(data);
    } catch (error) {
      toast.error("Failed to fetch property types: " + error);
      return null;
    }
  };

  const fetchLocalities = async () => {
    try {
      const { data, error } = await supabase.from("localities").select();

      if (error) {
        throw error;
      }

      setLocalities(data);
    } catch (error) {
      toast.error("Failed to fetch localities: " + error);
      return null;
    }
  };

  const updateHotel = async (e, id) => {
    e.preventDefault();

    if (!name || !code || !provider || !locality || !apiId) {
      alert(
        "Hotel name, code, service provider, locality, and API ID are required."
      );
      return;
    }

    if (
      name === originalData.name &&
      code === originalData.code &&
      propertyType === originalData.property_type &&
      provider === originalData.api_provider &&
      locality === originalData.locality &&
      apiId === originalData.api_identifier &&
      active === originalData.active
    ) {
      toast.info("Nothing was changed!");

      return;
    }

    toast.promise(
      supabase
        .from("hotels")
        .update({
          name: name.trim(),
          code: code.trim(),
          property_type_id: propertyType,
          api_provider_id: provider,
          locality_id: locality,
          api_identifier: apiId.trim(),
          active: active,
        })
        .eq("id", id)
        .single()
        .then(({ data, error }) => {
          if (error) throw error;
          return data;
        }),
      {
        pending: `Updating ${name}...`,
        success: {
          render({ data }) {
            navigate("/hotels");
            return `${name} successfully updated!`;
          },
        },
        error: {
          render({ data }) {
            return (
              <>
                <p>Updating {name} failed!</p>
                <p className={styles.info}>Reason: {data.message}</p>
              </>
            );
          },
        },
      }
    );
  };

  useEffect(() => {
    if (params.id) {
      fetchHotel(params.id);
      fetchPropertyTypes();
      fetchProviders();
      fetchLocalities();
    }
  }, [params.id, navigate]);

  return (
    <>
      <div className={styles.main_container}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <h4 className={styles.page_title}>
              Hotels <IoChevronForwardSharp /> Edit <IoChevronForwardSharp />{" "}
              {name}
            </h4>

            <form
              onSubmit={(e) => {
                updateHotel(e, params.id);
              }}
            >
              <div className={styles.form_container}>
                <label htmlFor="name" className={styles.labels}>
                  Name
                </label>
                <input
                  required
                  type="text"
                  placeholder="Hotel name"
                  id="name"
                  name="name"
                  className={styles.textboxes}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label htmlFor="code" className={styles.labels}>
                  Code
                </label>
                <input
                  required
                  type="text"
                  placeholder="Code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  id="code"
                  name="code"
                  className={styles.textboxes}
                />

                <label htmlFor="property_type" className={styles.labels}>
                  Property type
                </label>
                <select
                  required
                  className={styles.textboxes}
                  name="property_type"
                  id="property_type"
                  value={propertyType}
                  onChange={(e) => setPropertyType(e.target.value)}
                >
                  <option value="">Select property type</option>
                  {propertyTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="locality" className={styles.labels}>
                  Locality
                </label>
                <select
                  required
                  className={styles.textboxes}
                  name="locality"
                  id="locality"
                  value={locality}
                  onChange={(e) => setLocality(e.target.value)}
                >
                  <option value="">Select locality</option>
                  {localities.map((l) => (
                    <option key={l.id} value={l.id}>
                      {l.name}
                    </option>
                  ))}
                </select>

                <label htmlFor="provider" className={styles.labels}>
                  Service Provider
                </label>
                <select
                  required
                  className={styles.textboxes}
                  name="provider"
                  id="provider"
                  value={provider}
                  onChange={(e) => setProvider(e.target.value)}
                >
                  <option value="">Select service provider</option>
                  {providers.map((provider) => (
                    <option key={provider.id} value={provider.id}>
                      {provider.name}
                    </option>
                  ))}
                </select>

                <label htmlFor="api_identifier" className={styles.labels}>
                  Resort Code
                </label>
                <input
                  required
                  type="number"
                  placeholder={10}
                  value={apiId}
                  onChange={(e) => setApiId(e.target.value)}
                  id="api_identifier"
                  name="api_identifier"
                  className={styles.textboxes}
                />
                <div className={styles.active}>
                  <input
                    type="checkbox"
                    value={active}
                    checked={active}
                    onChange={() => setActive(!active)}
                    id="active"
                    name="active"
                    className={styles.checkbox}
                  />
                  <label
                    htmlFor="active"
                    className={`${styles.labels} ${styles.checkbox_label}`}
                  >
                    Active
                  </label>
                </div>
                <div className={styles.fn_btns}>
                  <button
                    onClick={() => navigate("/hotels")}
                    title="Return to client list"
                    className="neutral_btn"
                  >
                    Cancel
                  </button>
                  <button type="submit" title="Update this hotel">
                    Update hotel
                  </button>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
}

export default EditHotel;
